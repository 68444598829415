// Text utilities align
.u-textCenter {
	text-align: center;
}

.u-textRight {
	text-align: right;
}

.u-textBold {
	font-weight: bold;
}

.u-textItalic {
	font-style: italic;
}
.u-textUnderline {
	text-decoration: underline;
}
.u-textNoWrap {
	white-space: nowrap;
}

// Utillities voor font-size
// u-textXs   = 0.5rem
// u-textS    = 0.75rem
// u-textM    = 1.1rem
// u-textL    = 1.25rem
// u-textXl   = 1.5rem
// u-textXxl  = 1.75rem
// u-textXxxl = 2rem

.u-textS {
	font-size: 0.75rem;
}

.u-textM {
	font-size: 0.95rem;
}

.u-textL {
	font-size: 1.15rem;
}

.u-textXl {
	font-size: 1.5rem;
}

.u-textXxl {
	font-size: 2rem;
}

.u-textXxxl {
	font-size: 3rem;
}