.Button {
	text-decoration: none;
	background: $color-primary;
	color: $color-white;
	padding: 0.3em 1em;
	border-radius: $border-radius-round;
	display: inline-block;
	border: 0;
	cursor: pointer;
	transition: $transition;
	font-size: 1.1em;
	font-family: $font-header;
	font-weight: bold;
	width: fit-content;

	&:hover,
	&:active
	&:focus {
		outline: 0;
		background: $color-secondary;
		color: $color-white;
	}
}

.Button--white {
    background-color: $color-white;
    color: $color-primary-dark;

    &:hover,
	&:active
	&:focus {
		background-color: $color-white;
		color: $color-secondary-dark;
	}
}

.Button--secondary {
	background-color: $color-secondary;

	&:hover,
	&:active
	&:focus {
		background-color: $color-secondary-dark;
	}
}

.Button--round {
    border-radius: $border-radius-round;
}

.Button--clean {
	background: transparent;
	color: currentColor;
	padding: 0;
	margin-right: 0;
	
	&:hover,
	&:active
	&:focus {
		background-color: transparent;
		color: currentColor;
		margin-right: -0.25em;
	}
}

.Button--cleanWhite {
	background-color: transparent;
	color: $color-white !important;

	&:hover,
	&:active
	&:focus {
		background-color: transparent;
		color: $color-white !important;
	}
}

.Button--center {
	margin: 0 auto;
}

.Button--right {
	display: inherit;
	text-align: right;
	margin-left: auto;
}

.Button--social {
	padding: 0;
	width: 2em;
	height: 2em;
    display: flex;
    align-items: center;
	justify-content: center;
}

.Button--border {
	background: transparent;
	color: currentColor;
	border: 1px solid $color-gray-light;
	font-size: 0.9em;
	font-weight: normal;
}