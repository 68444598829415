.Article-quote {
    font-size: 2.2em;
    font-family: $font-header;
	font-style: italic;
    font-weight: bold;
    line-height: 1.2;
    position: relative;

    &:before {
        content: '"';
        position: absolute;
        color: $color-secondary;
        font-family: $font-special;
        font-size: 2.2em;
        left: -0.6em;
        top: -0.25em;
    }
}

blockquote {
    font-size: 2.2em;
    font-family: $font-header;
    font-style: italic;
    font-weight: bold;
    line-height: 1.2;
    position: relative;

    &:before {
        content: '"';
        position: absolute;
        color: $color-secondary;
        font-family: $font-special;
        font-size: 2.2em;
        left: -0.6em;
        top: -0.25em;
    }
}