.Footer {
    position: relative;
    z-index: 1;
    color: $color-white;
}

.Footer-figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    &:after {
        content: '';
        background: $background-hero-gradient;
        opacity: 0.8;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
    }
}

.Footer-figure .figureImage,
.Footer-figureImage {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.Footer-content {
    position: relative;
    z-index: 1;
    padding-top: 5em;
    padding-bottom: 5em;
    background-color: rgba($color-primary-dark,0.1);
}

.Footer-copy {
    position: relative;
    color: $color-white;
    z-index: 1;
    font-size: 0.8em;
    padding-top: 1em;
    padding-bottom: 1em;
    @include media-query($breakpoint-l) {
        display: flex;
        justify-content: space-between;
    }
}