.Carousel {
    position: relative;
}

.Carousel-wrapper {
    overflow: hidden;
}

.Carousel-content {
    min-width: calc(75rem - 6em);
}

.Carousel-button {
    position: absolute;
    top: 35%;
    z-index: -1;
    font-size: 1.25em;
}

.Carousel-button--prev {
    left: -1.5em;
    padding-right: 1.75em;
    padding-left: 0.4em;
}

.Carousel-button--next{
    right: -1.5em;
    padding-left: 1.75em;
    padding-right: 0.4em;
}

//  <div class="Carousel">
//         <div class="Carousel-wrapper">
//             <div class="Carousel-content">
//                 <div class="Grid Grid--smallGap">
//                     {% for teammember in teammembers %}
//                         {% set loopIndex = loop.index %}
//                         {% if loopIndex < 8 %}
//                         <div class="Grid-cell u-size1of5">
//                             <a href="{{teammember.url}}" class="Card Card--hover Card--team">
//                                 <div class="Card-figure">
//                                     <img class="Card-figureImage" src="{{teammember.img}}" alt="{{teammember.alt}}">
//                                 </div>
//                                 <div class="Card-content">
//                                     <h3 class="Card-title">{{teammember.firstname}}{{teammember.lastname}}</h3>
//                                     <p class="Card-intro">{{teammember.intro}}</p>
//                                 </div>
//                             </a>
//                         </div>
//                         {% endif %}
//                     {% endfor %}
//                 </div>
//             </div>
//         </div>
//         <div class="Carousel-button Carousel-button--prev Button"><svg class="Icon"><use xlink:href="#Icon--arrow-left"></use></svg></div>
//         <div class="Carousel-button Carousel-button--next Button"><svg class="Icon"><use xlink:href="#Icon--arrow-right"></use></svg></div>
//     </div>
//     <a href="ons-team.html" class="Button Button--clean Button--right Button--cleanWhite u-marginTs">Alle teamleden & partners <svg class="Icon"><use xlink:href="#Icon--arrow-right"></use></svg></a>
// </div> 