
// Contactform 7
.wpcf7-form-control-wrap.your-name,
.wpcf7-form-control-wrap.your-email,
.wpcf7-form-control-wrap.your-message {
    width: 100%;
}

.grecaptcha-badge {
    z-index: 200;
    box-shadow: none !important;
    .grecaptcha-logo {
        display: none;
    }
}