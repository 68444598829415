$breakpoint-m:			30em; //  480px
$breakpoint-l:			40em; //  640px
$breakpoint-xl:			55em; //  880px
$breakpoint-xxl:		60em; //  960px
$breakpoint-xxxl:		80em; // 1280px
$breakpoint-xxxxl:		90em; 
$breakpoint-xxxxxl:		110em; 

$font-fallback-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-header: 'PT Sans', sans-serif; 
$font-default: 'PT Sans', sans-serif;
$font-special: 'Caveat', sans-serif;


$color-gray-light:      #ddd;
$color-gray:            #828080;
$color-gray-dark:       #383838; 
$color-black:           #000;
$color-white:           #fff;
$color-white-off:       #d0cfce;

$color-primary:         #2e2e63;
$color-primary-dark:    #050532;

// limegreen
$color-secondary:       #75ce16;//#9cbd36;
$color-secondary-dark:  #65b311; //#9cbd36;


// Gradient with pink: 
$background-hero-gradient: linear-gradient(110deg, rgba($color-primary-dark,1) 0%, rgba($color-primary,1) 55%, rgba($color-secondary,0) 100%);
$background-hero-gradient-transparant: linear-gradient(110deg, rgba($color-primary-dark,0.85) 0%, rgba($color-primary,0.85) 55%, rgba($color-secondary,0) 100%);

// no pink
$background-gradient: linear-gradient(110deg, rgba($color-primary-dark,1) 0%, rgba($color-primary,1) 100%);
$background-gradient-transparant: linear-gradient(110deg, rgba($color-primary-dark,0.85) 0%, rgba($color-primary,0.85) 100%);

$border-radius: 9px;
$border-radius-round: 10em;
$box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1);
$box-shadow-inset: inset 0 0 5px 3px rgba(0, 0, 0, 0.1);
$transition: 0.5s ease-in-out all;