.Header {
    position: fixed;
    z-index: 10;
    transition: $transition;
    width: 100%;

    background: rgba($color: $color-white, $alpha: 1);
    color: $color-primary;
    padding-top: 0;
    box-shadow: $box-shadow;

}

.Header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.Header-contact {
    z-index: 2;

    .Header-contactIcons {
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        align-items: center;
        // display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-direction: row;

        .Header-contactIcons-item {
            margin-right: 1em;

            a {
                &:focus,
                &:active,
                &:hover {
                    color: $color-secondary;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.Header-title {
    z-index: 2;
}

.Header-logo {
    padding: 1em 0 1.5em;
}

.Header-nav {
    order: 2;

    @include media-query($breakpoint-xxxl) {
        order: inherit;
    }
}

.logged-in #wpadminbar {
    position: fixed;
    top: 0;
}