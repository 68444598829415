.u-borderRadius {
    border-radius: $border-radius;
    overflow: hidden;
}

.u-borderRadiusRound {
    border-radius: $border-radius-round;
    overflow: hidden;
}

.u-borderColorGray {
    border: 1px solid $color-gray-light;
}