.HeroHome {
    position: relative;

    @include media-query($breakpoint-xxl) {
        min-height: 90vh;
        overflow: hidden;
        box-shadow: $box-shadow-inset;
    }
}

.HeroHome-figure {
    position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
    overflow: hidden;
    z-index: -1;
}

.HeroHome-figure .figureImage,
.HeroHome-figureImage {
	min-height: 100%;
	min-width: 1024px;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.HeroHome-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.HeroHome-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $color-white;
    position: relative;
    min-height: 90vh;
    width: 100%;
    padding: 5em 0 0;

    @include media-query($breakpoint-xxl) {
        padding: 0;
        flex-direction: row;
    }
}

.HeroHome-contentWrapper {
    position: relative;
    z-index: 2;
    text-align: center;
    max-width: 275px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-query($breakpoint-xxl) {
        margin-left: 4em;
        top: 37vh;
    }

    @include media-query($breakpoint-xxxl) {
        position: absolute;
        text-align: left;
        margin-left: 0;
        left: 19vw;
    }
}

.HeroHome-title {
    position: relative;
    font-size: 2em;
    line-height: 1.2;
    margin-bottom: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;

    .Icon {
        margin-right: 0.25em;
        margin-top: -0.1em;
    }
    @include media-query($breakpoint-xxxl) {
        justify-content: flex-start;
    }
}

.HeroHome-intro {
    position: relative;

    @include media-query($breakpoint-xxxl) {
        margin-left: -12vw;

        &:before {
            content: '';
            shape-outside: polygon(0 0, 0 350px, 11vw 0px);
            width: 300px;
            height: 250px;
            float: left;
        }
    }
}

.HeroHome-content-1,
.HeroHome-content-2,
.HeroHome-content-3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(100% 0%, 100% 85%, 50% 100%, 0% 85%, 0% 0%);    // https://bennettfeely.com/clippy/
    width: 100%;
    padding: 1px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    @include media-query($breakpoint-xxl) {
        height: 90vh;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        min-height: inherit !important;
        margin-top: initial !important;
        padding-top: initial !important;
        position: absolute;
        width: 43.3vw;
    }

    @include media-query($breakpoint-xxxl) {
        width: 46vw;
    }

    @include media-query($breakpoint-xxxxl) {
        width: 50vw;
    }
}

.HeroHome-content-1 {
    min-height: 55vh;
    z-index: 3;
    padding-top: 4em;
    padding-bottom: 2em;

    &:after {
        background-color: rgba($color-primary-dark, 0.85);
    }
    @include media-query($breakpoint-xxl) {
        clip-path: polygon(75% 0%, 100% 55%, 75% 100%, 0% 100%, 0% 0%);
        left: -7vw;
    }

    @include media-query($breakpoint-xxxl) {

        .HeroHome-contentWrapper {
            left: initial;
            right: 7vw;
        }
    }

    @include media-query($breakpoint-xxxxl) {
        left: -11vw;
    }
}

.HeroHome-content-2 {
    min-height: 55vh;
    margin-top: -8.3vh;
    z-index: 2;
    padding-top: 4em;
    padding-bottom: 2em;
    clip-path: polygon(100% 0,100% 85%,50% 100%,0 85%,0 0,50% 15%);

    &:after {
        background-color: rgba($color-primary, 0.9);
    }

    @include media-query($breakpoint-xxl) {
        clip-path: polygon(75% 0%, 100% 55%, 75% 100%, 0% 100%, 25% 55%, 0% 0%);
        transform: translateX(-2.9vw);

        .HeroHome-contentWrapper {
            max-width: 240px;
        }

        @-moz-document url-prefix() { //firefox fix
            margin-left: 0;
        }
    }

    @include media-query($breakpoint-xxxl) {
        clip-path: polygon(72% 0%, 100% 55%, 72% 100%, 0% 100%, 28% 55%, 0% 0%);
        width: 41vw;
        transform: translateX(-1.6vw);

        .HeroHome-contentWrapper {
            left: 17.5vw;
        }
    }

    @include media-query($breakpoint-xxxxl) {
        clip-path: polygon(68% 0%, 100% 55%, 68% 100%, 0% 100%, 32% 55%, 0% 0%);
        transform: translateX(-3.65vw);
    }
}
//ipad only
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
    .HeroHome-content-2 {
        transform: translateX(-3vw);
        width: 44.5vw;
    }
}
//@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
//    //.ipad-landscape { color: blue; } /* your css rules for ipad landscape */
//    .HeroHome-content-2 {
//        transform: inherit;
//        width: 43.5vw;
//    }
//}
//.HeroHome-content-2 {
//    @include media-query($breakpoint-xxl) {
//        margin-left: -1.9rem;
//    }
//    @include media-query($breakpoint-xxxl) {
//        margin-left: -1.15rem;
//    }
//    @include media-query($breakpoint-xxxxl) {
//        margin-left: -3rem;
//    }
//}
//
//.HeroHome-content-2 {
//    @include media-query($breakpoint-xxl) {
//        margin-left: -5.7vw;
//    }
//    @include media-query($breakpoint-xxxl) {
//        margin-left: -3vw;
//    }
//    @include media-query($breakpoint-xxxxl) {
//        margin-left: -7vw;
//    }
//}
//
////firefox
//.HeroHome-content-2,  x:-moz-any-link {
//
//}
//
////opera only
//@media all and (min-width: 0px){
//    .HeroHome-content-2 {
//        @include media-query($breakpoint-xxl) {
//            margin-left: -2.9vw;
//        }
//        @include media-query($breakpoint-xxxl) {
//            margin-left: -1.5vw;
//        }
//        @include media-query($breakpoint-xxxxl) {
//            margin-left: -3.6vw;
//        }
//    }
//}
////safari only
//_:default:not(:root:root), .HeroHome-content-2 {
//    @include media-query($breakpoint-xxl) {
//        margin-left: -2.9vw;
//    }
//    @include media-query($breakpoint-xxxl) {
//        margin-left: -1.5vw;
//    }
//    @include media-query($breakpoint-xxxxl) {
//        margin-left: -3.6vw;
//    }
//}
////Microsoft Edge 12-18 (all EdgeHTML) Not Chromium
//@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:"*") { body { border:1px solid red; } }


.HeroHome-content-3 {
    min-height: 55vh;
    margin-top: -8.3vh;
    z-index: 1;
    padding-top: 4em;
    padding-bottom: 2em;
    clip-path: polygon(50% 14%, 100% 0%, 100% 100%, 0 100%, 0 0);

    &:after {
        background-color: rgba($color-secondary, 0.85);
    }

    @include media-query($breakpoint-xxl) {
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 25% 55%, 0% 0%);
        right: -0.75em;
        z-index: 4;
    }

    @include media-query($breakpoint-xxxl) {
        right: -4vw;

        .HeroHome-contentWrapper {
            left: 18vw;
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { //ie11>
    .HeroHome-intro:before {
        display: none !important;
        width: initial;
        height: initial;
        shape-outside: initial;
        position: absolute;
    }
    .HeroHome-content-1,
    .HeroHome-content-2,
    .HeroHome-content-3 {
        position: relative;
        left: initial;
        right: initial;
        min-height: initial;
        margin-top: initial;
        margin-left: initial;

    }
    .HeroHome-intro {
        margin-left: 0;
    }

    .HeroHome-contentWrapper {
        position: relative;
        left: initial !important;
        right: initial;
    }

    .HeroHome-content-1 .HeroHome-contentWrapper {
        position: absolute;
        right: 6em !important;
    }
    .HeroHome-content-3 .HeroHome-contentWrapper {
        position: absolute;
        left: 6em !important;
    }
}
@supports (-ms-ime-align:auto) { //edge
    .HeroHome-intro:before {
        display: none !important;
        width: initial;
        height: initial;
        shape-outside: initial;
        position: absolute;
    }
    .HeroHome-content-1,
    .HeroHome-content-2,
    .HeroHome-content-3 {
        position: relative;
        left: initial;
        right: initial;
        min-height: initial;
        margin-top: initial;
        margin-left: initial;

    }
    .HeroHome-intro {
        margin-left: 0;
    }

    .HeroHome-contentWrapper {
        position: relative;
        left: initial !important;
        right: initial;
    }

    .HeroHome-content-1 .HeroHome-contentWrapper {
        position: absolute;
        right: 6em !important;
    }
    .HeroHome-content-3 .HeroHome-contentWrapper {
        position: absolute;
        left: 6em !important;
    }

}
