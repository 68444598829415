// Utilities for working with Flexbox
.u-flex {
	display: flex;
}

.u-flexColumn {
	flex-direction: column;
}

.u-flexGrow {
	flex-grow: 1;
}

@include media-query($breakpoint-l) {
	.u-l-flexOrderLast {
		order: 1;
	}
}

.u-flexCenter {
	align-items: center;
	display: flex;
	justify-content: center;
}

.u-flexSpaceBetween {
	justify-content: space-between;
}

.u-flexDirectionColumn {
	flex-direction: column;
}

.u-flexAlignCenter {
	align-items: center;
}
