.Form {
	width: 100%;
	//margin: 0 auto;
}

.Form-field {
	margin-bottom: 1em;
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.Form-label {
	text-align: left;
	font-size: 0.85rem;
	opacity: 0.5;
	width: 100%;
}

.Form-input,
.Form-textarea,
.Form-select,
.Form-datepicker {
	display: inline-block;
	border: 1px solid $color-white;
	border-radius: $border-radius;
	font-size: 0.95em;
	color: $color-gray-dark;
	width: 100%;
	padding: 0.3em 0.75em 0.2em;
	min-height: 2.5em; // Fix voor IE11

	&::placeholder {
		color: $color-gray-dark;
	}

	&:active,
	&:hover,
	&:focus {
		outline: 0;
		border: 1px solid $color-gray-dark;
	}
}

.Form-textarea {
	padding: 0.3em 0.75em;
}

.Form-select {
	padding: 0.4em 0.5em 0.2em 0.5em;
	position: relative;
	cursor: pointer;
	height: 2.5em;
	appearance: none;
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 99% 50%;
	background-color: $color-white;
}

// Datepicker
.Form-datepicker {
	height: 2.5em;
}
[type="date"] {
	background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
	display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
	opacity: 0;
}


// Radiobox en checkbox
.Form-checkbox,
.Form-radiobox {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	user-select: none;
	font-size: 0.9em;
}

.Form-checkboxInput,
.Form-radioboxInput {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.Form-checkboxCheck,
.Form-radioboxCheck {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: $color-white;
	box-shadow: $box-shadow inset;
	border-radius: $border-radius;

	&:after {
		content: "";
		position: absolute;
		display: none;
		left: 9px;
		top: 3px;
		width: 8px;
		height: 15px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
}

.Form-checkbox:hover .Form-checkboxInput ~ .Form-checkboxCheck,
.Form-radiobox:hover .Form-radioboxInput ~ .Form-radioboxCheck {
	background-color: $color-gray;
	border: 1px solid $color-gray;
}
.Form-checkboxInput:checked ~ .Form-checkboxCheck:after,
.Form-radioboxInput:checked ~ .Form-radioboxCheck:after {
	display: block;
}
.Form-checkboxInput:checked ~ .Form-checkboxCheck,
.Form-radioboxInput:checked ~ .Form-radioboxCheck {
	background-color: $color-primary;
	border: 1px solid $color-primary;
}


.Form-submit {
	text-align: right;
	border-radius: $border-radius;
}

// Search
.Form--search {
	width: auto;
	position: relative;
	width: 100%;

	.Form-input {
		width: 100%;
		padding: 0.4em 1em 0.2em;
		// Make room for absolute search icon
		padding-right: 2em;
	}

	.Form-submit {
		position: absolute;
		right: 0.5em;
		top: 0.05em;
		cursor: pointer;
		z-index: 2;
		color: $color-secondary;
		font-size: 1.3em;
	}
}

.Form--border {

	.Form-checkboxCheck,
	.Form-radioboxCheck,
	.Form-select,
	.Form-datepicker,
	.Form-textarea,
	.Form-input {
		border: 1px solid $color-gray-light;

		&:active,
		&:hover,
		&:focus {
			outline: 0;
			border-color: $color-gray-dark;
		}
	}
}

// Round
.Form--round {

	.Form-select,
	.Form-datepicker,
	.Form-textarea,
	.Form-input {
		border-radius: $border-radius-round;
	}
}

.Form--borderLight {

	.Form-input {
		border: 1px solid $color-white-off;
	}
}


.Form--inline {
	position: relative;
	display: flex;
	max-width: 40em;

	.Form-submit {
		position: absolute;
		right: 0.5em;
		top: 0.25em;
		border: 0;
		appearance: none;
	}
}