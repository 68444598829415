
html {
	font-size: 112.5%; // 18px
}

body {
	line-height: 1.75;
	font-family: $font-default;
	color: $color-primary-dark;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
	line-height: 1.25;
	margin: 0.5em 0;
	font-family: $font-header;
	font-weight: normal;
	font-style: italic;
	font-weight: bold;
}

h1,
.h1 {
	font-size: 2.5rem;
}

h2,
.h2 {
	font-size: 2rem;
}

h3,
.h3 {
	font-size: 1.75rem;
}

h4,
.h4 {
	font-size: 1.5rem;
	line-height: 1.1;
}
