/* Search & Filter Styles */

.searchandfilter p {
    margin-top: 1em;
    display:inline-block;
}
.searchandfilter ul {
    display:inline-block;
    @extend %list-clean;
    width: 100%;
    display: flex;
    align-items: center;
}
.searchandfilter li {
    list-style: none;
    display: inline-block;
    padding-right: 10px;
    width: 100%;
    flex: 1;

    // submit button
    &:last-child {
        width: fit-content;
        flex: inherit;
        align-self: flex-end;
    }
}

/* If in a widget area make a single column by adding display block  */
.widget-area .searchandfilter li, .widget-area .searchandfilter p {
    display:block;
}
.searchandfilter label {
    display:block;
}
.searchandfilter h4 {
    text-align: left;
    font-size: 0.85rem;
    opacity: 0.5;
    width: 100%;
    font-weight: normal;
    margin-bottom: 0.5em;
}

.searchandfilter ul > li > ul:not(.children) {
    margin-left:0;
}

.searchandfilter {

    select {
        display: inline-block;
        border: 1px solid $color-white;
        border-radius: $border-radius;
        font-size: 0.95em;
        color: $color-gray-dark;
        width: 100%;

        padding: 0.4em 1.5em 0.2em 0.5em;
        position: relative;
        cursor: pointer;
        min-height: 2.5em; // Fix voor IE11
        height: 2.5em;
        appearance: none;
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 99% 50%;
        background-color: $color-white;
        border: 1px solid $color-gray-light;

        &::placeholder {
            color: $color-gray-dark;
        }

        &:active,
        &:hover,
        &:focus {
            outline: 0;
            border: 1px solid $color-gray-dark;
        }
    }

    input[type="submit"] {
        text-decoration: none;
        background: $color-primary;
        color: $color-white;
        padding: 0.3em 1em;
        border-radius: $border-radius-round;
        display: inline-block;
        border: 0;
        cursor: pointer;
        transition: $transition;
        font-size: 1.1em;
        font-family: $font-header;
        font-weight: bold;
        width: fit-content;

        &:hover,
        &:active
        &:focus {
            outline: 0;
            background: $color-secondary;
            color: $color-white;
        }
    }
}