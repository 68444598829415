// Spacing utilities

// Used to adjust the default spacing between components
// Use with great care!
//
// u-<type><direction><size>
//
// A  = all
// T  = top
// R  = right
// B  = bottom
// L  = left
// H  = horizontal
// V  = vertical
//
// n  = none
// a  = auto
// s  = small
// m  = medium
// l  = large
// xl = extra large
//

.u-marginAn {
	margin: 0;
}
.u-marginAa {
	margin: auto;
}

// Bottom
.u-marginBn {
	margin-bottom: 0;
}

.u-marginBs {
	margin-bottom: 0.5em;
}

.u-marginBm {
	margin-bottom: 1em;
}

.u-marginBl {
	margin-bottom: 2em;
}

.u-marginBxl {
	margin-bottom: 3em;
}

// Top
.u-marginT-5 {
	margin-top: -5em;
}

.u-marginTa {
	margin-top: auto;
}

.u-marginTn {
	margin-top: 0;
}

.u-marginTs {
	margin-top: 0.5em;
}

.u-marginTm {
	margin-top: 1em;
}

.u-marginTl {
	margin-top: 2em;
}

.u-marginTxl {
	margin-top: 3em;
}

// Right
.u-marginRs {
	margin-right: 0.5em;
}

.u-marginRm {
	margin-right: 1em;
}

.u-marginRl {
	margin-right: 2em;
}

// Left
.u-marginLs {
	margin-left: 0.5em;
}

.u-marginLm {
	margin-left: 1em;
}

.u-marginLl {
	margin-left: 2em;
}

// Vertical
.u-marginVs {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.u-marginVm {
	margin-top: 1em;
	margin-bottom: 1em;
}

.u-marginVl {
	margin-top: 2em;
	margin-bottom: 2em;
}

.u-marginVxl {
	margin-top: 3em;
	margin-bottom: 3em;
}

.u-marginVxxl {
	margin-top: 4em;
	margin-bottom: 4em;
}

//Horizontal
.u-marginHs {
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.u-marginHm {
	margin-left: 1em;
	margin-right: 1em;
}