/**
 * Lays out cells in a row, with automatically row wrapping.
 *
 * See: https://github.com/suitcss/components-grid/blob/master/lib/grid.css
 * See: http://philipwalton.github.io/solved-by-flexbox/demos/grids/
 */

// Grid container, should only have Grid-cells as children
.Grid {
	display: flex;
	flex-wrap: wrap;
	// Compensate for the gutter
	margin-left: -2em;
}

.Grid-cell {
	// Cells are equal height by default
	display: flex;
	// Prepare cell content to take up all available space within a cell
	flex-direction: column;
	// This creates a gutter between cells
	padding-left: 2em;
	// By default, move cells below each other. Using utility classes it is
	// possible to set the cell sizes (per breakpoint), in order to display them
	// next to each other.
	width: 100%;
}

// Modifier: horizontally center a grid cell
.Grid-cell--center {
	margin: 0 auto;
	justify-content: center; //?
	align-items: center; //?
}

// Modifier: vertically align a grid cell to the bottom
.Grid-cell--bottom {
	align-self: flex-end;
}

.Grid--smallGap {
	margin-left: -1em;

	> .Grid-cell {
		padding-left: 1em;

		.Card {
			margin-bottom: 1em;
		}
	}
}

.Grid--bigGap {
	margin-left: -3em;

	> .Grid-cell {
		padding-left: 3em;

		.Card {
			margin-bottom: 3em;
		}
	}
}

.Grid--noGap {
	margin-left: -0em;

	> .Grid-cell {
		padding-left: 0em;
		
	}
}

.Grid--hideLast {

	@include media-query($breakpoint-xxxl) {
		.Grid-cell:last-child {
			display: none;
		}
	}
}

//.Grid--hideLast {
//
//	.Grid-cell:last-child {
//		display: none;
//		@include media-query($breakpoint-xxl) {
//			display: flex;
//		}
//	}
//}