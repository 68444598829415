// Flexible media embeds
//
// For use with media embeds – such as videos, slideshows, or even images –
// that need to retain a specific aspect ratio but adapt to the width of their
// containing element.
//
// Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
// Source: https://github.com/suitcss/components-flex-embed
.FlexEmbed {
	display: block;
	margin: 0 0 1em 0;
	overflow: hidden;
	position: relative;
}

.FlexEmbed--noSpace {
	margin: 0;
}

// The aspect-ratio is applied to an empty element because it allows
// the component to respect `max-height`. Default aspect ratio is 1:1.
.FlexEmbed-ratio {
	display: block;
	padding-bottom: 100%;
	width: 100%;
}

// A custom ratio can be applied using an inline padding-bottom style

// Modifier: 16:9 aspect ratio
.FlexEmbed-ratio--16by9 {
	padding-bottom: 56%;
}

.FlexEmbed-ratio--application {
	padding-bottom: 110%;

	@include media-query($breakpoint-l) {
		padding-bottom: 50%;
	}
	@include media-query($breakpoint-xxl) {
		padding-bottom: 30%;
	}
}
// Fit the content to the aspect ratio
.FlexEmbed-content {
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

/* stylelint-disable plugin/selector-bem-pattern */
// Disable this stylelint rule because we can't control the contents
// .FlexEmbed-content in all situations
.FlexEmbed-content iframe {
	border: 0;
	height: 100%;
	width: 100%;
}

.FlexEmbed-content div {
	height: 100%;
}

/* stylelint-enable */
