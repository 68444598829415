/** @define utilities */

// Display Utilities
.u-displayHidden {
	display: none;
}

// Hide visually, but the content is available for screen readers
.u-hiddenVisually {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.screen-reader-text {
	order: 0;
	clip: rect(1px,1px,1px,1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute!important;
	width: 1px;
	word-wrap: normal!important;
}

.u-overflowHidden {
	overflow: hidden;
}

.u-displayBlock {
	display: block;
}

// Hide on small screens, show on big screens
.u-l-displayFlex {
	display: none;

	@include media-query($breakpoint-l) {
		display: flex;
	}
}

.u-m-displayBlock {
	display: none;

	@include media-query($breakpoint-m) {
		display: block;
	}
}

.u-l-displayBlock {
	display: none;

	@include media-query($breakpoint-l) {
		display: block;
	}
}

.u-xl-displayBlock {
	display: none;

	@include media-query($breakpoint-xl) {
		display: block;
	}
}

.u-xxl-displayBlock {
	display: none;

	@include media-query($breakpoint-xxl) {
		display: block;
	}
}

.u-xxxl-displayBlock {
	display: none;

	@include media-query($breakpoint-xxxl) {
		display: block;
	}
}

.u-l-displayFlex {
	display: none;

	@include media-query($breakpoint-l) {
		display: flex;
	}
}

.u-xl-displayFlex {
	display: none;

	@include media-query($breakpoint-xl) {
		display: flex;
	}
}

.u-xxl-displayFlex {
	display: none;

	@include media-query($breakpoint-xxl) {
		display: flex;
	}
}

// Hide on big screens, show on small screens
.u-m-displayHidden {
	display: block;

	@include media-query($breakpoint-m) {
		display: none;
	}
}

.u-l-displayHidden {
	display: block;

	@include media-query($breakpoint-l) {
		display: none;
	}
}

.u-xl-displayHidden {
	display: block;

	@include media-query($breakpoint-xl) {
		display: none;
	}
}

.u-xxl-displayHidden {
	display: block;

	@include media-query($breakpoint-xxl) {
		display: none;
	}
}

.u-xxxl-displayHidden {
	display: block;

	@include media-query($breakpoint-xxxl) {
		display: none;
	}
}