/**
 * Dropdown effect
 * Used for main-menu dropdown
 */
@mixin dropdown($status: default) {
    @if ($status != open) {
        padding: 0;
        overflow: hidden;
        max-height: 0;
        transition: all 0.3s ease;
    }
    @else if ($status == open) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        max-height: 999px;
    }
}

/**
 * Submenu indicator
 */
@mixin sub-menu-indicator($status: default) {
    @if ($status != active) {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            font-size: 1.25rem;
            pointer-events: none; // prevent link activation on click
        };
    }
    @else if ($status == active) {
        &:before {
            content: "";
        };
    }
}

%list-clean {
    // Remove bullets
    list-style: none;
    margin-bottom: 0;
    // Remove default space allocated for bullets
    margin-top: 0;
    padding-left: 0;
}