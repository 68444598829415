.Background {
    position: relative;

    &:after {
        content: '';
        background-color: rgba($color-gray-light, 0.3);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
    }
}

.Background-content {
    position: relative;
    padding-top: 1em;
    padding-bottom: 1em;

    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    .Container {
        width: 100%;
    }
}

.Background-title {
    position: relative;
    padding-bottom: 0;
    margin-top: 0;
    font-size: 2.5em;
    line-height: 1;
    word-break: break-word;
    color: $color-primary-dark;
}

.Background-subtitle {
    font-size: 1em;
    color: $color-secondary-dark;
    text-transform: uppercase;
    font-style: normal;
}

.Background-intro {
    margin-top: -1em;
}

.Background--white {
    background-color: rgba($color-white, 0.95);
}

.Background--gray {
    background-color: rgba($color-gray-light, 0.3);
}

.Background--vh {
    min-height: 100vh;
    width: 100%;
}
