.List {
	// Remove bullets
	list-style: none;
	margin-bottom: 0;
	// Remove default space allocated for bullets
	margin-top: 0;
	padding-left: 0;
}

.List--horizontal {
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	.List-item {
		margin-bottom: 1em;
		margin-right: 1em;
	}
}

.List--center {
	justify-content: center;
}

.List--small {
	.List-item {
		margin-bottom: 0.5em;
		margin-right: 0.5em;
	}
}

.List--withIcon {

	.List-item {
		position: relative;
		margin-left: 1.75em;
		margin-bottom: 0.5em;

		.Icon {
			position: absolute;
			font-size: 1.25em;
			left: -1.5em;
			top: 0.2em;
		}
	}
}
