.u-linkClean {
	color: inherit;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: $color-secondary;
		text-decoration: none;
	}
}

.u-linkSecondary {
	color: $color-secondary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: $color-secondary;
		text-decoration: underline;
	}
}