.u-colorWhite {
    color: $color-white;
}

.u-colorBlack {
    color: $color-black;
}

.u-colorPrimary {
    color: $color-primary;
}

.u-colorSecondary {
    color: $color-secondary;
}