.Hero {
    position: relative;
    min-height: 90vh;
    overflow: hidden;
    box-shadow: $box-shadow-inset;
}

.Hero-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $color-white;
    position: relative;
    min-height: 90vh;
    width: 100%;
    max-width: 40em;
    padding: 15em 0 6em 0;
}

.Hero-figure {
    position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
    overflow: hidden;
    z-index: -1;

	&:after {
        content: '';
        background: $background-hero-gradient;
        opacity: 0.8;
        position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
	}
}

.Hero-figure .attachment-header,
.Hero-figureImage {
	min-height: 100%;
	min-width: 1024px;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.Hero-contentImage {
    position: absolute;
    right: -20em;
    margin-top: 1em;
}

.Hero-title {
    position: relative;
    font-size: 2em;
    line-height: 1.2;
    margin-bottom: 0.3em;
    margin-top: 0;

    display: flex;
    align-items: center;

    .Icon {
        font-size: 1.25em;
        margin-right: 0.25em;
        margin-top: -4px;
    }
}

.Hero-subtitle {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    color: $color-secondary;
    a {
        text-decoration: none;
    }
}

.Hero-intro {
    position: relative;
}

.Hero-more {
    color: $color-white;
    position: absolute;
    margin: auto;
    bottom: 1em;
    left: calc(50% - 0.5em);
    font-size: 2em;
}

.Hero--small {
    min-height: 60vh;
    
    .Hero-content {
        min-height: 60vh;
        padding: 15em 0 6em 0;
    }
}

.Hero--half {
    margin-top: -10em;
    z-index: 0;

    .Hero-content {
        padding-top: 11em;
    }
}

.Hero--pullBottom {
    margin-bottom: -12em;

    .Hero-content {
        padding-bottom: 11em;
    }
}

.Hero--grid {

    .Hero-content {
        max-width: inherit;

        @include media-query($breakpoint-xxl) {
            flex-direction: row;
            justify-content: space-between;
        }
        
        @include media-query($breakpoint-xxxl) {
            margin-left: 5em;
            margin-right: -8em;
        }
    }

    .Hero-contentWrapper {
        text-align: center;
        
        @include media-query($breakpoint-xxl) {
            max-width: 500px; 
            padding-right: 4em;

        }
        @include media-query($breakpoint-xxxl) {
            padding-right: 8em;
            text-align: left;
        }
    }
}

.Hero--quote {

    .Hero-title {
        font-size: 1.5em;
    }
}

.Hero--white {
    min-height: inherit;
    box-shadow: inherit;
    background-color: rgba($color-gray-light, 0.3);
    .Hero-content {
        min-height: inherit;
        color: $color-primary;
        padding: 3em 0 3em 0;
    }
}