
// On mobile there is a pure CSS dropdown menu when you click on the label/hamburger

.Nav {
    display: flex;
    align-items: center;

}

.Nav-menu .Nav-wrapper {
    top: -60em;
}

.Nav-wrapper {
    top: -60em;
    position: absolute;
    transition: $transition;
    z-index: 1;
    background: $color-white;
    color: $color-primary;
    padding-top: 7em;
    padding-bottom: 3em;
    box-shadow: $box-shadow;
    width: 100%;
    left: 0;
    max-height: 100vh;
    overflow: scroll;

    @include media-query($breakpoint-xxxl) {
        // On large screens we want to see the menu anyway
        z-index: 1;
        top: 0;
        right: 0;
        position: relative;
        padding: 0;
        background: transparent;
        box-shadow: none;
        height: inherit;
        max-width: inherit;
        width: inherit;
        max-height: inherit;
        overflow: inherit;

        .Container {
            padding: 0;
        }
    }
}

.Nav-list {
    position: relative;
    z-index: 1;

	// Remove bullets
	list-style: none;
    margin-bottom: 0;
    
	// Remove default space allocated for bullets
	margin-top: 0;
    padding-left: 0;

    @include media-query($breakpoint-xxxl) {
        // On larger screens show items next to each other
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
}

.menu-item,
.Nav-list-item {
    //margin-bottom: 0.5em;

    &:last-child {
        margin-bottom: 0;
    }

    @include media-query($breakpoint-xxxl) {
        margin-right: 1em;
    }
}

.Nav-link {
    text-decoration: none;
    border-bottom: 1px solid $color-gray-light;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $color-secondary;

    }
    @include media-query($breakpoint-xxxl) {
        border-bottom: 0;
        padding: 0;
    }
}

.Nav-toggle {
    display: none;
}

.Nav-label {
    cursor: pointer;
    // To align the icons on the same height
    line-height: 1; 
    // To put the icons on top of the Nav-wrapper
    position: relative;
    z-index: 2;
    font-size: 1.5em;
    margin-left: 0.5em;
}

.Nav-closeIcon {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
}

.Nav-close {
    cursor: pointer;
    height: 0vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

// Acties voor de toggle
.Nav-toggle:checked~.Nav-wrapper {
    top: 0;
}

.Nav-toggle:checked~.Nav-close {
    height: 100vh;
}

.Nav-toggle:checked~.Nav-label>.Icon--menu {
    display: none;
}

.Nav-toggle:checked~.Nav-label>.Icon--close {
    display: block;
}

// custom button menu
.Nav-list > .menu-item:last-child .Nav-link {
    text-decoration: none;
    background: $color-primary;
    color: $color-white;
    padding: 0.3em 1em;
    border-radius: $border-radius-round;
    display: inline-block;
    border: 0;
    cursor: pointer;
    transition: $transition;
    font-size: 1.1em;
    font-family: $font-header;
    font-weight: bold;
    width: fit-content;
    margin: 1em auto;

    &:hover,
    &:active
    &:focus {
        outline: 0;
        background: $color-secondary;
        color: $color-white;
    }

    @include media-query($breakpoint-xxxl) {
        margin: inherit;
        margin-right: 0;
    }
}

//.menu-item-has-children > .Lang-link,
//.menu-item-has-children > .Nav-link,
.menu-item-has-children {
    position: relative;
    cursor: pointer;

    &:after {
        content: url("../metier/assets/images/chevron-down.svg");
        position: absolute;
        display: block;
        right: -1em;
        top: 0;
        cursor: pointer;
        padding: 0.75em 1em 0.15em;
        z-index: 10;
        transition: $transition;
    }

    &:hover:after {
        content: url("../metier/assets/images/chevron-down-green.svg");
    }

    .sub-menu {
        //max-height: 0;
        //overflow: hidden;
        transition: $transition;
        opacity: 0;
        display: none;
    }

    &.active {

        &:after {
            transform: rotate(-180deg);
            top: 0;
        }
        .sub-menu {
            //max-height: 20em;
            opacity: 1;
            display: block;
        }
    }
}


// Pretty for mobile
ul#menu-header-menu-nl > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

ul#menu-header-menu-nl .sub-menu { /* this targets all sub menus */
    position: relative;
    background: $color-white;
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    //margin-top: 0.5em;
    //border-top: 1px solid $color-gray-light;

    li {
        position: relative;
        margin-bottom: 0;

        a {
            margin-bottom: 0;
            transition: $transition;
            display: flex;
            align-items: center;
            padding: 0.25em 1.5em 0.25em 1em;
            border-bottom: 1px solid $color-gray-light;
            font-size: 0.9em;
            opacity: 0.8;

            &:before {
                content: url("../metier/assets/images/arrow-down.svg");
                position: relative;
                transform: rotate(-90deg) scale(0.7);
                color: currentColor;
            }
        }
    }

}

//Submenu on large screens
@include media-query($breakpoint-xxxl) {

    ul#menu-header-menu-nl .sub-menu { /* this targets all sub menus */
        //display: none; /* hide all sub menus from view */
        position: absolute;
        top: 30px; /* this should be the same height as the top level menu -- height + padding + borders */
        z-index: 0;
        background: $color-white;
        padding: 1.5em 0 0;
        margin-left: -1em;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        list-style: none;
        margin-bottom: 0;
        border-top: 0;
        margin-top: 0;
        box-shadow: 0 1em 1em 0 rgba(0,0,0,.1);
        width: 20em;
    }
    ul#menu-header-menu-nl .sub-menu li { /* this targets all submenu items */
        margin-right: 0;
        margin-bottom: 0;
        line-height: 1.5;
        padding-top: 0.15em;
        padding-bottom: 0.25em;

        &:last-child a {
            border-bottom: 0;
        }
    }
    .menu-item-has-children {
        margin-right: 1.75em;

        &:after {
            transform: scale(0.7);
            right: -1.75em;
            top: -.4em;
            padding: 0.5em;
        }
        &.active {
            &:after {
                transform: scale(0.7) rotate(-180deg);
                top: -0.80em;
                right: -1.85em;
            }
        }
    }
}

.logged-in .Nav--languages {
    display: block;

    ul#menu-language-switcher .sub-menu { /* this targets all sub menus */
        position: absolute;
        top: 30px; /* this should be the same height as the top level menu -- height + padding + borders */
        z-index: 0;
        background: $color-white;
        padding: 1.5em 0 0;
        margin-left: -1em;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        list-style: none;
        margin-bottom: 0;
        border-top: 0;
        margin-top: 0;
        box-shadow: 0 1em 1em 0 rgba(0,0,0,.1);
    }
    ul#menu-language-switcher .sub-menu li { /* this targets all submenu items */
        margin-right: 0;
        margin-bottom: 0;
        line-height: 1.5;
        padding-top: 0.15em;
        padding-bottom: 0.25em;

        &:last-child a {
            border-bottom: 0;
        }
        position: relative;
        margin-bottom: 0;

        a {
            margin-bottom: 0;
            transition: $transition;
            display: flex;
            align-items: center;
            padding: 0.25em 1.5em 0.25em 1em;
            border-bottom: 1px solid $color-gray-light;
            font-size: 0.9em;
            opacity: 0.8;
            text-decoration: none;

            &:before {
                content: url("../metier/assets/images/arrow-down.svg");
                position: relative;
                transform: rotate(-90deg) scale(0.7);
                color: currentColor;
            }
            &:hover,
            &:focus,
            &:active {
                color: $color-secondary;
            }
        }
    }

    .menu-item-has-children {
        margin-right: 1.75em;

        &:after {
            transform: scale(0.7);
            right: -1.75em;
            top: -.4em;
            padding: 0.5em;
        }
        &.active {
            &:after {
                transform: scale(0.7) rotate(-180deg);
                top: -0.80em;
                right: -1.85em;
            }
        }
    }

    a {
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: $color-secondary;
        }
    }
}
