.Card {
    position: relative;
    text-decoration: none;
    margin-bottom: 2em;
    transition: $transition;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    border-radius: $border-radius;
    overflow: hidden;
}

.Card-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.Card-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.5%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;

    &:after {
        content: '';
        background: $background-gradient-transparant;
        opacity: 0.2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
    }
}

.Card-figure .figureImage,
.Card-figureImage {
    min-height: 100%;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    transition: $transition;
}

.Card-content {
    position: relative;
    z-index: 1;
    padding: 1em 1.5em 1.5em;
    transition: $transition;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    background-color: rgba($color-white, 1);
    @include media-query($breakpoint-m) {
        padding: 1em 2.25em 1.5em;
    }
    &:before {
        content: '';
        opacity: 1;
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 1em solid white;
        border-left: 35em solid transparent;
        top: -1em;
        right: 0;
        z-index: -1;
    }
}

.Card-contentImage {
    text-align: center;
    margin-bottom: 1em;
    margin-top: 1em;
}

.Card-meta {
    transition: $transition;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
    font-size: 0.8em;
    
    .Card-metaItem {
        margin: 0;
        margin-right: 1em;
        transition: $transition;

        .Icon {
            margin-top: -0.15em;
            margin-right: 0.25em;
        }
    }
}   

.Card-title {
    width: 100%;
    margin: 0;
    word-break: break-word;
    margin-bottom: auto; // Als er geen card-intro is
    line-height: 1.2;
    transition: $transition;
}

.Card-subtitle {
    font-weight: bold;
	font-style: italic;
    line-height: 1.3;
    transition: $transition;
    margin: 0.5em 0;
    font-size: 1.1em;
}

.Card-price {
    font-weight: bold;
    line-height: 1.3;
    transition: $transition;
    font-size: 1.25em;
    margin: 0.25em 0;
    color: $color-secondary;
}

.Card-intro {
    margin: 0.5em 0 0 0;
    line-height: 1.5;
    flex-grow: 1;
    transition: $transition;
    font-size: 0.95em;

    a {
        position: relative;
        z-index: 11;
    }
}

.Card-button {
    margin-top: 1em;
}

.Card-button {
    padding-top: 2.5em;
    color: $color-white;
    line-height: 1.2;
    transition: $transition;
    text-align: right;

    .Icon {
        margin-top: -0.15em;
    }

    &:after {
        content: '';
        background: $color-secondary;
        opacity: 1;
        position: absolute;
        bottom: -2em;
        right: -1em;
        width: 120%;
        height: 6em;
        z-index: -1;
        transform: rotate(-1.7deg);
        transition: $transition;
    }
}

// HOVER 
.Card--hover {
    cursor: pointer;
    
    &.is-active,
    &:active,
    &:focus,
    &:hover {

        .Card-figure .figureImage,
        .Card-figureImage {
            transform: translate(-50%, -50%) scale(1.075);
        }

        .Card-button {
            margin-right: -0.5em;

            &:after {
                height: calc(100% + 2.8em);
            }
        }

        .Card-metaItem,
        .Card-intro,
        .Card-subtitle,
        .Card-price,
        .Card-title {
            color: $color-white;
            z-index: 1;
        }
    }
}

.Card--border {
    border: 1px solid $color-white-off;

    &.Card--hover {
    
        &.is-active,
        &:active,
        &:focus,
        &:hover {
            border: 1px solid $color-secondary;
        }
    }
}

.Card--logo {

    .Card-figure {
        padding-bottom: 100%;
        
        &:after {
            opacity: 0;
        }
    }

    .Card-figure .figureImage,
    .Card-figureImage {
        padding: 1em;
        filter: saturate(0);
        object-fit: contain;
    }

    &:active,
    &:focus,
    &:hover {

        .Card-figure .figureImage,
        .Card-figureImage {
            filter: initial;
        }
    }
}

.projecten .Card--logo {
    .Card-figure .figureImage,
    .Card-figureImage {
        filter: initial;
    }
}

.Card--square {

    .Card-figure {
        padding-bottom: 75%;
    }
    .Card-figure .figureImage,
    .Card-figureImage {
        padding: 2em 2em 0 2em;
        object-fit: contain;
    }
}

.Card--image {
        
    .Card-figure {
        padding-bottom: inherit;
        height: 100%;
        position: absolute;

        &:after {
            opacity: 0.8;
        }
    }

    .Card-content {
        position: relative;
        background-color: transparent;
        padding: 8em 2em 1.5em;
        text-align: left;
    }

    .Card-title {
        font-size: 1.75em;
        margin-bottom: 0;
        color: $color-white;
    }

    .Card-intro {
        color: $color-white;
    }
    
    .Card-button {
        padding-top: 3em;
        
        &:after {
            opacity: 0.9;
        }
    }

    &.is-active,
    &:active,
    &:focus,
    &:hover {

        .Card-figure .figureImage,
        .Card-figureImage {
            transform: translate(-50%, -50%) scale(1.075);
        }
        .Card-intro,
        .Card-title {
            color: rgba($color-white, 1);
        }
        .Card-button {
            color: $color-white;

            &:after {
                height: 40em;
            }
        }
    }
}

.Card--clean {

    .Card-content {
        padding-top: 2em;
    }
}


.Card--smallPadding {

    .Card-content {
        padding: 1em;
    }
}

.Card--team {

    .Card-figure {
        padding-bottom: 100%;

        &:after {
            opacity: 0.4;
        }
    }
    
    .Card-content {
        background-color: $color-secondary;
        padding: 1em 1.5em 1.5em;

        &:before {
            border-bottom: 1em solid $color-secondary;
        }
    }

    .Card-intro,
    .Card-title {
        color: $color-white;
    }

    .Card-title {
        font-size: 1.4em;
    }
}
