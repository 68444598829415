/**
 * Horizontally centered content area.
 */

.Container {
	margin: 0 auto;
	max-width: 80rem;
	padding: 0 1.5em;
}

@include media-query($breakpoint-m) {

	.Container {
		padding: 0 2em;
	}
}

@include media-query($breakpoint-xxl) {

	.Container {
		padding: 0 3em;
	}
}

// Modifier: full width container on smaller viewports
.Container--fullWidth {
	// padding: 0;
	max-width: inherit;
}

.Container--small {
	max-width: 67rem;
}